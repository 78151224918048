const $ = jQuery;

class Cookie {
  static set(name, val, path) {
    document.cookie = `${name}=${escape(val)}; path=${path}`;
  }
  static get(name) {
    const cookies = document.cookie.split(';');
    let value = null;

    for (let i = 0; i < cookies.length; i++) {
      const cookieName = cookies[i].replace(/^\s+/, '');

      if (cookieName.substring(0, name.length + 1) === `${name}=`) {
        value = unescape(cookieName.substring(name.length + 1));
        break;
      }
    }
    return value;
  }
}

Vue.directive('inview', {
  inserted: function(el) {
    setInview(el);
  }
});

Vue.directive('swipe-right', {
  inserted: function(el, binding, vnode) {
    var hammer = new Hammer(el);

    hammer.on('swipe', function(event) {
      if (event.direction === 4) {
        if (typeof binding.expression !== 'undefined' &&
            binding.expression in vnode.context &&
            typeof vnode.context[binding.expression] === 'function') {
          vnode.context[binding.expression]();
        }
      }
    });
  }
});

var setLoadingEvent = function($container, callback) {
  var imgs = [],
      $img;

  if ($container.prop('tagName').toLowerCase() === 'img') {
    $img = $container;
  } else {
    $img = $container.find('img');
  }

  $container.add($container.find('*')).each(function() {
    var $this = $(this),
        imageList = [];
    imageList.push($this.css('background-image'));
    imageList.push(window.getComputedStyle($this[0], '::before').getPropertyValue('background-image'));
    imageList.push(window.getComputedStyle($this[0], '::after').getPropertyValue('background-image'));
    imageList.forEach(function(image) {
      if (image !== 'none') {
        $.each(image.match(/url\(.+?\)/g), function(index, value) {
          var file = value.replace(/(url\(|\)|\")/g, ''); // eslint-disable-line no-useless-escape
          if (file.indexOf('data') !== 0) {
            file = convertUrl(file);
            imgs.push(file);
          }
        });
      }
    });
  });
  $img.each(function() {
    var file = $(this).attr('src');
    if (file.indexOf('data') !== 0) {
      file = convertUrl(file);
      imgs.push(file);
    }
  });
  imgs = unique(imgs);
  imgLoadCompleteCallback(imgs, callback);

  function convertUrl(src) {
    return $('<a>').attr('href', src).get(0).href;
  }

  function unique(array) {
    var storage = {},
        uniqueArray = [],
        i, value;
    for (i = 0; i < array.length; i++) {
      value = array[i];
      if (!(value in storage)) {
        storage[value] = true;
        uniqueArray.push(value);
      }
    }
    return uniqueArray;
  }

  function imgLoadCompleteCallback(files, completeCallback) {
    var fileLength = files.length,
        completeCount = 0;

    if (fileLength === 0) {
      completeCallback();
    } else {
      for (var i = 0; i < fileLength; i++) {
        loadimg(files[i]);
      }
    }

    function loadimg(file) {
      var $img = $(new Image());
      $img.one('load error', function(event) {
        $img.off(event.type === 'load' ? 'error' : 'load');
        completeCount++;
        if (completeCount === fileLength) {
          completeCallback();
        }
      });
      $img.attr('src', file);
    }
  }
};

var ResizeManage = function() {
  this.initialize.apply(this, arguments);
};
ResizeManage.prototype = {
  initialize: function(breakpoint) {
    var _this = this;
    this.isMobile = $window.width() < breakpoint;
    this.$element = $('<div></div>');

    $window.on('resize', function() {
      if ($window.width() >= breakpoint) {
        if (_this.isMobile) {
          _this.isMobile = false;
          _this.$element.triggerHandler('resizeManage');
        }
      } else {
        if (!_this.isMobile) {
          _this.isMobile = true;
          _this.$element.triggerHandler('resizeManage');
        }
      }
    });
  }
};

function arrayShuffle(array) {
  var i, j, k;
  for (i = array.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    k = array[i];
    array[i] = array[j];
    array[j] = k;
  }
}

function setInview(element) {
  new ScrollMagic.Scene({
    triggerElement: element,
    triggerHook: 0.7,
    reverse: false
  })
    .setClassToggle(element, 'is-inview')
    .addTo(scrollMagic);
}

function gdpr() {
  $('.c-cookie .c-cookie__accept').on('click', function() {
    $.post('/wp-json/gdpr/accept');
    $('.c-cookie').remove();
    return false;
  });
}

var $slider = $('.js-topSlider');
function topSlider() {
  $slider.slick({
    autoplay: false,
    autoplaySpeed: 6000,
    arrows: false,
    dots: true,
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 3000,
    swipe: true,
  });
}

function opening() {
  var $header = $('.js-header');

  $header.addClass('is-active');
  $slider.slick('slickPlay');
}

function setPhoto() {
  $('.js-instagram').each(function() {
    var $element = $(this),
        element = this;

    $.ajax({
      dataType: 'json',
      timeout: 10000,
      url: 'https://welcometoasia2020.shuttlerock.com/v2/tags/' + $element.data('hashtag') + '/entries.json'
    }).done(function(data) {
      var orderData = $element.data('photo-order'),
          photoData = [],
          order = [];

      if ($.isArray(orderData)) {
        order = orderData;
      } else if (orderData === 'shuffle') {
        order = [1, 2, 3, 4, 5, 6, 7, 8];
        arrayShuffle(order);
      }
      $.each(data.slice(0, 8), function(index, value) {
        photoData[index] = {
          url: value.details.link_to_url,
          imageSrc: value.images.large_square,
          orderClass: order[index] ? 'inview-order' + order[index] : false,
          authorName: value.author.name,
          authorPicture: value.author.picture
        };
      });
      new Vue({
        el: element,
        data: {
          photos: photoData
        },
        methods: {
          modal: function(event, data) {
            if (resizeManage.isMobile) {
              vueHub.$emit('modal.instagram', event, data);
            }
          }
        }
      });
    });
  });
}

function instagramModal() {
  var $container = $('.l-container'),
      modalElement = document.querySelector('.js-instagramModal'),
      scrollPosition = 0;

  if (modalElement === null) {
    return;
  }
  new Vue({
    el: modalElement,
    data: {
      isOpen: false,
      url: '',
      imageSrc: '',
      authorName: '',
      authorPicture: ''
    },
    created: function() {
      var _this = this;
      vueHub.$on('modal.instagram', function(event, data) {
        _this.url = data.url;
        _this.imageSrc = '';
        _this.authorName = data.authorName;
        _this.authorPicture = '';
        _this.$nextTick(function() {
          _this.imageSrc = data.imageSrc;
          _this.authorPicture = data.authorPicture;
          _this.open();
        });
        event.preventDefault();
      });
      resizeManage.$element.on('resizeManage', function() {
        if (!resizeManage.isMobile && _this.isOpen) {
          _this.close();
        }
      });
    },
    methods: {
      open: function() {
        scrollPosition = $window.scrollTop();
        $container.css({
          'position': 'fixed',
          'width': '100%',
          'top': -scrollPosition + 'px'
        });
        this.isOpen = true;
      },
      close: function() {
        $container.css({
          'position': '',
          'width': '',
          'top': ''
        });
        $window.scrollTop(scrollPosition);
        this.isOpen = false;
      }
    }
  });
}

function winnersModal() {
  var $container = $('.l-container'),
      $winnersModal = $('.js-winnersModal').filter(function() {
        return $(this).closest('.SPTBview').siblings('a').length !== 0;
      }),
      scrollPosition = 0,
      modalControl = {
        close: function($modal) {
          $container.css({
            'position': '',
            'width': '',
            'top': ''
          });
          $window.scrollTop(scrollPosition);
          $modal.removeClass('is-open');
        },
        open: function($modal) {
          scrollPosition = $window.scrollTop();
          $container.css({
            'position': 'fixed',
            'width': '100%',
            'top': -scrollPosition + 'px'
          });
          $modal.addClass('is-open');
        }
      };

  if ($winnersModal.length === 0) {
    return;
  }

  $winnersModal.each(function() {
    var $modal = $(this),
        $anchor = $modal.closest('.SPTBview').siblings('a'),
        hammer = new Hammer($modal.find('.p-instagramModal__inner')[0]);

    $anchor.on('click', function() {
      if (resizeManage.isMobile) {
        modalControl.open($modal);
        return false;
      }
    });
    $modal.find('.c-link__back').on('click', function() {
      modalControl.close($modal);
    });
    hammer.on('swipe', function(event) {
      if (event.direction === 4) {
        modalControl.close($modal);
      }
    });
  });
  resizeManage.$element.on('resizeManage', function() {
    if (!resizeManage.isMobile) {
      $winnersModal.each(function() {
        var $modal = $(this);

        if ($modal.hasClass('is-open')) {
          modalControl.close($modal);
        }
      });
    }
  });
}

function contactDetailCounter() {
  var $detail = $('#f_detail');

  new Vue({
    el: '#detail_len',
    data: {
      counter: $detail.val().length
    },
    created: function() {
      var vm = this;

      $detail.on('input', function() {
        vm.counter = $detail.val().length;
      });
    }
  });
}

function moreArticleList() {
  var $moreButton = $('.p-archive .c-btn'),
      moreElement = document.querySelector('.p-archive .p-articleList__more'),
      articleList;

  if (moreElement === null) {
    return;
  }
  articleList = new Vue({
    el: moreElement,
    data: {
      articles: []
    }
  });
  $moreButton.find('a').on('click', function() {
    var anchor = this.href;

    $.ajax({
      dataType: 'html',
      timeout: 10000,
      url: anchor
    }).done(function(data) {
      var $data = $($.parseHTML(data)),
          $btn;

      $data.find('.p-archive .p-articleList').not('.p-articleList__more').find('.p-articleItem').each(function() {
        articleList.articles.push($(this).html());
      });
      $btn = $data.find('.p-archive .c-btn');
      if ($btn.length === 0) {
        $moreButton.remove();
      } else {
        $moreButton.find('a').attr('href', $btn.find('a').attr('href'));
      }
    });
    return false;
  });
}

function checkShowPopup() {
  const $popup = $('.l-popup'),
        $close = $popup.find('.l-popup__close');

  if (Cookie.get('popupClose') !== 'true') {
    $popup.addClass('-show');
    $close.one('click', event => {
      event.preventDefault();
      Cookie.set('popupClose', 'true', '/');
      $popup.removeClass('-show');
    });
  }
}

function checkTopModal() {
  const $popup = $('.l-topModal'),
        $close = $popup.find('.l-topModal__close');

  if ($popup.length !== 0 && Cookie.get('topModalClose') !== 'true') {
    $popup.addClass('-show');
    setLoadingEvent($popup, () => {
      setTimeout(() => {
        $popup.removeClass('-show');
      }, 5000);
    });
    $close.one('click', event => {
      event.preventDefault();
      Cookie.set('topModalClose', 'true', '/');
      $popup.removeClass('-show');
    });
  }
}

var $window = $(window),
    vueHub = new Vue(),
    $inviewTarget = $('.js-inview').not('[v-inview]'),
    scrollMagic = new ScrollMagic.Controller(),
    resizeManage = new ResizeManage(769);

gdpr();
topSlider();
opening();
setPhoto();
instagramModal();
winnersModal();
$inviewTarget.each(function() {
  setInview(this);
});
if ($('.mw_wp_form_input').length === 1) {
  contactDetailCounter();
}
moreArticleList();
checkShowPopup();
checkTopModal();
